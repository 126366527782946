import { IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { validateQRcodeHandler } from 'mimex-libs/api/endpoints'
import { ERROR, GENERIC_ERROR, PATH_HOME, PATH_VIRTUAL_CART, WARNING } from "mimex-libs/helpers/constants"
import { emitter, memoryStore, UserStoreChanged, UserStoreType } from 'mimex-libs/helpers/userStore'
import { createLogger } from 'mimex-libs/logger'
import {
    SessionEvent,
    ShoppingSessionStatus
} from 'mimex-libs/sdk/events/session.events'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom'
import useToast from '../../../CustomHooks/useToast'
import './QrcodeModal.css'

const logger = createLogger({ component: 'Qrcode' })

interface QrcodeModal {
    open?: boolean
    onClose: () => void
}

export function QrcodeModal(props: QrcodeModal) {
    const history = useHistory()
    const [processing, setProcessing] = useState(false)
    const [customError, setCustomError] = useState('')
    const [renderToast] = useToast()

    const { t } = useTranslation(["translation"]);

    const handleScan = (data: any) => {
        if (processing) return
        if (!data) return
        logger.info('get QR Code, try to validate')
        setProcessing(true)
        const qrcode = data.split('qrcode=')
        if (qrcode.length <= 1) {
            renderToast(ERROR, 'Invalid QR code')
            setProcessing(false)
            return
        }
        validateQRcodeHandler({ jwtToken: qrcode[1] })
            .then(() => {
                logger.info('QR Code validated')
            })
            .catch((e: any) => {
                setCustomError(e?.response?.data?.error?.message)
                renderToast(ERROR, e?.response?.data?.error?.message)
            })
            .finally(() => setProcessing(false))
    }

    useEffect(() => {

        const handleSessionEvent = (sessionEvent: SessionEvent) => {
            if (sessionEvent) {
                logger.debug(`Session event received ${sessionEvent.status}`)
                if (sessionEvent.status === ShoppingSessionStatus.CANCELLED) {
                    setProcessing(false)
                    memoryStore.set(UserStoreType.session, undefined, false)
                    renderToast(WARNING, "Session cancelled, scan again")
                    history.push({ pathname: PATH_HOME })
                    return
                }

                if (sessionEvent.status === ShoppingSessionStatus.QRCODE_ACCESS) {
                    logger.debug('QRcode confirmed, move to virtual cart')
                    setProcessing(false)
                    memoryStore.set(UserStoreType.session, undefined, false)
                    props.onClose && props.onClose()
                    history.push({ pathname: PATH_VIRTUAL_CART })
                    return
                }
            }
        }

        const sessionEvent = memoryStore.get(UserStoreType.session) as SessionEvent
        if (sessionEvent) {
            handleSessionEvent(sessionEvent)
        }

        const onSessionEvent = (ev: UserStoreChanged) => {
            if (ev.value) handleSessionEvent(ev.value as SessionEvent)
        }

        emitter.on(UserStoreType.session, onSessionEvent)

        return () => {
            emitter.off(UserStoreType.session, onSessionEvent)
        }
    }, [history, renderToast])

    const handleError = (e: Error) => {

        let msg = GENERIC_ERROR
        if (e.message.toString().indexOf('Permission') > -1) {
            msg = t("Please allow the application to use the camera")
        }

        logger.error(e, { ref: 'QrReader.handleError' })
        setCustomError(msg)
        renderToast(ERROR, msg)
    }

    const onQRCodeScan = () => {
        setCustomError('')
    }
    return (

        <IonModal isOpen={props.open}>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>{t("QR Code")}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">

                <div className={'Myflex'} data-testid="qrcode-page">
                    {!customError ? (
                        <>
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: '90%' }}
                            />
                        </>
                    ) : (
                        <div id="qrcode-reader">
                            <IonButton
                                data-testid="scan-qrcode-again-button"
                                expand="full"
                                color="primary"
                                fill="outline"
                                shape="round"
                                size="large"
                                onClick={() => onQRCodeScan()}
                            >
                                {t('Scan QR Code Again')}

                            </IonButton>
                        </div>
                    )}
                </div>

            </IonContent>
            <IonButton id='qrcode-modal' data-testid="qrcode-modal" slot="end" onClick={props.onClose}>
                {t("Cancel")}
            </IonButton>
        </IonModal>
    )
}
