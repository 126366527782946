import { PaymentMethod } from "@stripe/stripe-js";
import { AxiosResponse } from "axios";
import { CheckinStatusDto, ResetStoreDto, SessionSessionDto, JwtResponse, JwtResponseMimexResponse, PaymentMethodDtoListMimexResponse, ProductPriceDto, ReaderDto, ReceiptDto, ReceiptSummaryDto, SessionFindDto, StockSearchDto, StoreDto, StoreStatusDto, StreamingResponseDto, UserAccountDto, UserProfileDto, UserProfileInvoiceDto, UserSessionFindDto } from "../sdk";
import { NotificationMessage } from "../sdk/events/notification.events";
import { getClient } from "./sdk";



export const authGetUserHandler = () => {
  return getClient()
    ?.authControllerGetUser()
    .then((axiosResponse) => axiosResponse.data.data);
};
export const deleteAllProductsHandler = async () => {
  return await getClient()?.catalogControllerDeleteAllProducts();
};
export const exportCatalogHandler = async (storeId: string): Promise<string> => 
  await getClient()?.catalogControllerExportCatalog(storeId).then((res) => res.data.data)
  
export const exportPaymentsHandler = async (payload: any): Promise<string> => 
  await getClient()?.paymentControllerAdminExportPaymentsCSV(payload).then((res) => res.data.data)

export const exportSoldProductsHandler = async (payload: any): Promise<string> => 
  await getClient()?.paymentControllerAdminExportSoldProductsCSV(payload).then((res) => res.data.data)

export const exportUserSessionsHandler = async (payload: any): Promise<string> => 
  await getClient()?.paymentControllerAdminExportUserSessionsCSV(payload).then((res) => res.data.data)
  
export const getAllProductsHandler = async (storeId: string) => {
  return await getClient()
    ?.catalogControllerGetAllProductsByStore(storeId)
    .then((axiosResponse) => axiosResponse.data.data);
};
export const getProductHandler = async (storeId: string, productId: string) => {
  return await getClient()
    ?.catalogControllerGetProduct(storeId, productId)
    .then((axiosResponse) => axiosResponse.data.data);
};

export const getAllOOPHandler = async (storeId: string) => {
  return await getClient()
    ?.racksControllerGetOutOfPlace('all')
    .then((axiosResponse) => axiosResponse.data.data);
};

export const importCatalogHandler = async (binaryString: any) => {
  return await getClient()?.catalogControllerImportCatalog(binaryString);
};

export const insertProductHandler = async (insertProductPayload: any) => {
  return await getClient()
    ?.catalogControllerInsertProduct(insertProductPayload)
    .then((axiosResponse) => axiosResponse.data.data);
};

export const removeProductHandler = async (productId: string) => {
  return await getClient()
    ?.catalogControllerRemoveProduct(productId)
    .then((axiosResponse) => axiosResponse.data);
};

export const searchProductsHandler = async (storeId: string, queryProp: string) => {
  return await getClient()
    ?.catalogControllerSearchProducts(storeId, queryProp)
    .then((axiosResponse) => axiosResponse.data.data);
};

export const updateProductHandler = async (updateProductPayload: any) => {
  return await getClient()
    ?.catalogControllerUpdateProduct(updateProductPayload)
    .then((axiosResponse) => axiosResponse.data.data);
};

export const updateProductPriceHandler = async (storeId: string, productId: string, updatePricePayload: any) => {
  return await getClient()
    ?.pricingControllerUpdateProductPrice(storeId, productId, updatePricePayload)
    .then((axiosResponse) => axiosResponse.data);
};

export const deleteProductPriceHandler = async (storeId: string, productId: string) => {
  return await getClient()
    ?.pricingControllerDeleteProductPrice(storeId, productId)
    .then((axiosResponse) => axiosResponse.data);
};

export const validateQRcodeHandler = async (jwtToken: any) => {
  return await getClient()
    ?.checkinControllerValidateQRcode(jwtToken)
    .then((axiosResponse) => axiosResponse.data);
};
export const GetJWTHandler = async (username: string, password: string): Promise<string> => {
  return await getClient()
    ?.loginControllerGetJWT({ username, password })
    .then((axiosResponse: AxiosResponse<JwtResponseMimexResponse>) => axiosResponse.data.data.jwt);
};
export const SocialLoginHandler = async (auth_code: string, provider: string): Promise<string> => {
  return await getClient()
    ?.loginControllerSociallogin({ auth_code, provider })
    .then((axiosResponse: AxiosResponse<JwtResponseMimexResponse>) => axiosResponse.data.data.jwt);
};
export const resetPasswordHandler = async (username: string) => {
  return await getClient()
    ?.loginControllerResetPassword({
      username: username,
    })
    .then((axiosResponse) => axiosResponse.data);
};
export const createCardSecretHandler = async () => {
  return await getClient()
    ?.paymentControllerCreateCardSecret()
    .then((axiosResponse) => axiosResponse.data.data);
};
export const getPaymentCardSecretHandler = async (): Promise<PaymentMethod[]> => {
    return await getClient()
      ?.paymentControllerGetCardSecret()
      .then((axiosResponse: AxiosResponse<PaymentMethodDtoListMimexResponse>) => axiosResponse.data.data as PaymentMethod[]);
};
export const updateCardSecretHandler = async (paymentMethodId: string) => {
  return await getClient()
    ?.paymentControllerUpdateCardSecret({
      paymentMethodId,
    })
    .then((axiosResponse) => axiosResponse.data);
};
export const deletePaymentCardSecretHandler = async (id: string) => {
  return await getClient()
    ?.paymentControllerDeleteCardSecret(id)
    .then((axiosResponse) => axiosResponse.data);
};
export const registrationProcessHandler = async (registrationPayload: any) => {
  return await getClient()
    ?.registrationControllerRegistrationProcess({
      ...registrationPayload,
    })
    .then((axiosResponse) => axiosResponse.data.data);
};
export const virtualCartAddItemHandler = async (sessionId: string, item: any) => {
  return await getClient()?.virtualCartControllerAddItem(sessionId, item);
}
export const getVirtualCartHandler = async (sessionId: string) => {
  return await getClient()
    .virtualCartControllerGetVirtualCart(sessionId)
    .then((axiosResponse) => axiosResponse.data.data);
};
export const virtualCartRemoveItemHandler = async (sessionId: string, item: any) => {
  return await getClient()?.virtualCartControllerRemoveItem(sessionId, item)
}
export const createPaymentIntent = async (sessionId:string) => {
  return await getClient()
    ?.paymentControllerCreatePaymentIntent(sessionId)
    .then((axiosResponse) => axiosResponse.data);
  //throw new Error("Not implemented")
};
export const markPaymentAsSuccedeed = async (sessionId:string) => {
  return await getClient()
    ?.paymentControllerPaymentSucceeded(sessionId)
    .then((axiosResponse) => axiosResponse.data);
  //throw new Error("Not implemented")
};
export const currentShoppingSession = async () => {
  return await getClient()
    ?.shoppingControllerGetCurrentSession()
    .then((axiosResponse) => axiosResponse.data);
};
export const getAllShoppingSessions = async (sessionFindDto: SessionFindDto) => {
  return await getClient()
    ?.shoppingControllerAdminSearch(sessionFindDto)
    .then((axiosResponse) => axiosResponse.data);
};
export const deleteAllShoppingSessions = async (sessionFindDto: SessionFindDto) => {
  return await getClient()
    ?.shoppingControllerDeleteAll(sessionFindDto)
    .then((axiosResponse) => axiosResponse.data);
};
export const updateShoppingSession = async (sessionSessionDto: SessionSessionDto) => {
  return await getClient()
    ?.shoppingControllerUpdate(sessionSessionDto)
    .then((axiosResponse) => axiosResponse.data);
};
export const resetStore = async (resetStore: ResetStoreDto) => {
  return await getClient()
    ?.shoppingControllerResetStore(resetStore)
    .then((axiosResponse) => axiosResponse.data);
};
export const getAllUsersSessions = async ( ) => {
  return await getClient()
    ?.shoppingControllerGetCurrentSession()
    .then((axiosResponse) => axiosResponse.data);
};
export const getCurrentUserInfo = async (profileId: string): Promise<UserProfileDto> => {
  return await getClient()
    ?.profileControllerRead({profileId})
    .then((axiosResponse) => axiosResponse.data.data);
};
export const updateCurrentUserInfo = async (profileId: string): Promise<UserProfileDto> => {
  return await getClient()
    ?.profileControllerSave({
 
        id: "",
        name: "",
        surname:  "",
        phone:  "",
    })
    .then((axiosResponse) => axiosResponse.data.data);
};
export const refreshToken = async (): Promise<JwtResponse> => {
  return await getClient()
    ?.loginControllerRefreshToken()
    .then((axiosResponse) => axiosResponse.data.data);
};
export const getUserAccount = async (): Promise<UserAccountDto> => {
  return await getClient()
    ?.authControllerGetUser()
    .then((axiosResponse) => axiosResponse.data.data);
};
export const getUserReceipts = async (query: UserSessionFindDto): Promise<ReceiptDto[]> => {
  return await getClient()
    ?.shoppingControllerGetUserReceipts(query)
    .then((axiosResponse) => axiosResponse.data.data);
};
export const getUserReceiptsSummary = async (query: UserSessionFindDto): Promise<ReceiptSummaryDto[]> => {
  return await getClient()
    ?.shoppingControllerGetUserReceiptsSummary(query)
    .then((axiosResponse) => axiosResponse.data.data);
};
export const updateUserPaymentStatus = async (userPayload:UserAccountDto): Promise<UserAccountDto> => {
  return await getClient()
    ?.authControllerUpdateUser(userPayload)
    .then((axiosResponse) => axiosResponse.data.data);
};
// ============= STORES =============
export const getStoresList = async (): Promise<StoreDto[]> => {
  return await getClient()
    ?.storesControllerList()
    .then((axiosResponse) => axiosResponse.data.data);
};
export const userUpdateInfoInInvoice = async (userProfileInvoiceDto: UserProfileInvoiceDto): Promise<UserProfileDto> => {
  return await getClient()
    ?.profileControllerSaveInvoice(userProfileInvoiceDto)
    .then((axiosResponse) => axiosResponse.data.data);
};

export const getStoreStatus = async (
  storeId: string,
): Promise<StoreStatusDto> => {
  return await getClient()
    ?.storesControllerGetStatus(storeId)
    .then((axiosResponse) => axiosResponse.data.data);
};

export const setStoreStatus = async (
  storeId: string,
  storeStatus: StoreStatusDto
): Promise<StoreStatusDto> => {
  return await getClient()
    ?.storesControllerSetStatus(storeId, storeStatus, {})
    .then((axiosResponse) => axiosResponse.data.data);
};

// ============= Racks - APIs =============
export const createRackHandler = async (rackPayload: any) => {
    return await getClient()
        ?.racksControllerCreateRack(rackPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const deleteAllRacksHandler = async () => {
    return await getClient()
        ?.racksControllerDeleteAllRacks()
        .then((axiosResponse) => axiosResponse.data)
}
export const deleteRackHandler = async (rackId: string) => {
    return await getClient()
        ?.racksControllerDeleteRackById(rackId)
        .then((axiosResponse) => axiosResponse.data)
}
export const getAllRacksHandler = async () => {
    return await getClient()
        ?.racksControllerGetAllRacks()
        .then((axiosResponse) => axiosResponse.data.data)
}
export const getRackHandler = async (rackId: string) => {
    return await getClient()
        ?.racksControllerGetRackById(rackId)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const updateRackHandler = async (
    rackId: string,
    updatedRackPayload: any
) => {
    return await getClient()
        ?.racksControllerUpdateRack(rackId, updatedRackPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const searchRacksHandler = async (queryProp: string) => {
    return await getClient()
        ?.racksControllerSearchRacks(queryProp)
        .then((axiosResponse) => axiosResponse.data.data)
}
// ============= Readers - APIs =============
export const checkReaderHealthStatusHandler = async (readerPayload: any) => {
    return await getClient()
        ?.readersControllerCheckReaderHealthStatusById(readerPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const checkAllReaderHealthStatusHandler = async () => {
    return await getClient()
        ?.readersControllerCheckReadersHealthStatus()
        .then((axiosResponse) => axiosResponse.data.data)
}
export const createReaderHandler = async (readerPayload: any) => {
    return await getClient()
        ?.readersControllerCreateReader(readerPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const deleteAllReadersHandler = async () => {
    return await getClient()
        ?.readersControllerDeleteAllReaders()
        .then((axiosResponse) => axiosResponse.data)
}
export const deleteReaderHandler = async (readerId: string) => {
    return await getClient()
        ?.readersControllerDeleteReaderById(readerId)
        .then((axiosResponse) => axiosResponse.data)
}
export const exportReaderConfigurationHandler = async () => {
    return await getClient()
        ?.readersControllerExportReadersConfigurations()
        .then((axiosResponse) => axiosResponse.data.data)
}
export const getAllReadersHandler = async () => {
    return await getClient()
        ?.readersControllerGetAllReaders()
        .then((axiosResponse) => axiosResponse.data.data)
}
export const getReaderCalibrationHandler = async (readerId: string) => {
    return await getClient()
        ?.readersControllerGetCalibrationByReaderId(readerId)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const getReaderHandler = async (readerId: string) => {
    return await getClient()
        ?.readersControllerGetReaderById(readerId)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const importReaderConfigurationHandler = async () => {
    return await getClient()
        ?.readersControllerImportReadersConfigurations()
        .then((axiosResponse) => axiosResponse.data)
}
export const updateReaderHandler = async (
    readerPayload: ReaderDto,
) => {
    return await getClient()
        ?.readersControllerUpdateReader(readerPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const searchReadersHandler = async (queryProp: string) => {
    return await getClient()
        ?.readersControllerSearchReaders(queryProp)
        .then((axiosResponse) => axiosResponse.data.data)
}
// ============= Weight - APIs =============
export const analyzeCellSignal = async (
  readerId: string,
  cellId: string
) => {
  return await getClient()
      ?.weightControllerAnalyzeSignal(readerId, cellId)
      .then((axiosResponse) => axiosResponse.data)
}
export const weightCalibW0 = async (
    readerId: string,
    cellId: string
) => {
    return await getClient()
        ?.weightControllerCalibCellW0(readerId, cellId)
        .then((axiosResponse) => axiosResponse.data)
}
export const weightCalibWRef = async (
    readerId: string,
    cellId: string,
    refWeight: number
) => {
    return await getClient()
        ?.weightControllerCalibCellWRef(readerId, cellId, { data: { refWeight: refWeight }})
        .then((axiosResponse) => axiosResponse.data)
}

export const enableWeightStream = async (
  readerId: string,
  cellId: string, 
  params?: any) => {
  return await getClient()
      ?.weightControllerEnableStream(readerId, cellId, params)
      .then((axiosResponse) => axiosResponse.data)
  }

  export const disableWeightStream = async (
    readerId: string,
    cellId: string
    ) => {
    return await getClient()
        ?.weightControllerDisableStream(readerId, cellId, {})
        .then((axiosResponse) => axiosResponse.data)
    }

  export const readCellWeight = async (
    readerId: string | undefined,
    cellId: string | undefined
    ) => {
    return await getClient()
        ?.weightControllerCurrentWeight(readerId, cellId, {})
        .then((axiosResponse) => axiosResponse.data)
    }

  export const readCellStatus= async (
    readerId: string | undefined,
    cellId: string | undefined
    ) => {
    return await getClient()
        ?.weightControllerCellStatus(readerId, cellId, {})
        .then((axiosResponse) => axiosResponse.data)
    }

  export const readCellState= async (
    readerId: string | undefined,
    cellId: string | undefined
    ) => {
    return await getClient()
        ?.weightControllerCellState(readerId, cellId, {})
        .then((axiosResponse) => axiosResponse.data)
    }

    export const getWeightStatus= async () => {
      return await getClient()
          ?.weightControllerStatus({})
          .then((axiosResponse) => axiosResponse.data.data)
      }

// ============= Stocks - APIs =============
export const createStockHandler = async (stockPayload: any) => {
    return await getClient()
        ?.stocksControllerCreate(stockPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const deleteAllStocksHandler = async () => {
    return await getClient()
        ?.stocksControllerRemoveAll()
        .then((axiosResponse) => axiosResponse.data)
}
export const deleteStockHandler = async (stockId: string) => {
    return await getClient()
        ?.stocksControllerRemove(stockId)
        .then((axiosResponse) => axiosResponse.data)
}
 
export const getStockHandler = async (stockId: string) => {
    return await getClient()
        ?.stocksControllerRead(stockId)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const resetStockHandler = async (stockPayload: any) => {
    return await getClient()
        ?.stocksControllerResetRackStockAvailability(stockPayload)
        .then((axiosResponse) => axiosResponse.data)
}
export const updateStockHandler = async (
    stockId: string,
    stockPayload: any
) => {
    return await getClient()
        ?.stocksControllerUpdate( stockPayload)
        .then((axiosResponse) => axiosResponse.data.data)
}
export const searchStocksHandler = async (queryProp: StockSearchDto) => {
    return await getClient()
        ?.stocksControllerSearch(queryProp)
        .then((axiosResponse) => axiosResponse.data.data)
}

export const getAlertStocksHandler = async (storeId: string) => {
  return await getClient()
      ?.stocksControllerGetAlerts(storeId)
      .then((axiosResponse) => axiosResponse.data.data)
}

export const getExpireStocksHandler = async (storeId: string) => {
  return await getClient()
      ?.stocksControllerGetExpiring(storeId)
      .then((axiosResponse) => axiosResponse.data.data)
}
// ============= Admin - APIs =============
export const importDBFile = async (file: any) => {
    return await getClient()
        ?.adminDatabaseControllerImport(file)
        .then((axiosResponse) => axiosResponse.data)
}
export const resetDB = async () => {
    return await getClient()
        ?.adminDatabaseControllerImportEnv('default-data')
        .then((axiosResponse) => axiosResponse.data)
}
export const createQrCode = async (storeId: string): Promise<any> => {
  const axiosResponse = await getClient().checkinControllerCreateQRcode(storeId)
  return axiosResponse.data.data.jwtToken
}
export const checkinStatus = async (storeId: string): Promise<CheckinStatusDto> => {
  const axiosResponse = await getClient().checkinControllerStatus(storeId)
  return axiosResponse.data.data
}
 
export const getTotalSales = async (period: string): Promise<any> => {
  const axiosResponse = await getClient().paymentControllerListPaymentsFiltered(period)
  return axiosResponse.data.data
}
export const searchNotification = async (storeId: string, limit?: number, skip?: number): Promise<any> => {
  const axiosResponse = await getClient().notificationControllerStatus(storeId,  limit, skip)
  return axiosResponse.data.data
}
export const readNotification = async (storeId: string, notificationId: string): Promise<any> => {
  return await getClient().notificationControllerMarkAsRead(storeId, notificationId)  
}

export const deleteNotificationByType = async(storeId: string, message: NotificationMessage): Promise<any> =>{
  return await getClient().notificationControllerRemoveAllByType(storeId, message)
}

export const deleteNotifications = async(storeId: string): Promise<any> =>{
  return await getClient().notificationControllerRemoveAll(storeId)
}

export const enableVideoStream = async (storeId: string, camId: string, resX: number, resY: number): Promise<StreamingResponseDto> => {
  const axiosResponse = await getClient().streamingControllerEnableStream({ storeId: storeId, camId: camId, width: resX, height: resY })
  return axiosResponse.data.data
}

// ============= Object Storage - APIs =============

export const getAsset = async (id: string): Promise<any> => {
  const res = await getClient().assetsControllerServe(id, {responseType: "arraybuffer"})
  return `data:${res.headers["content-type"]};base64,${Buffer.from(res.data as any, "binary").toString("base64")}`
} 

export const saveImage = async (file: File): Promise<any> => {
  const axiosResponse =  await getClient().assetsControllerSave(file)
  return axiosResponse.data
} 

export const removeImage = async (id: string): Promise<void> => {
  await getClient().assetsControllerRemove(id)
} 

// ============= Pricing - APIs =============

export const createPricing = async (storeId: string, productPrice: ProductPriceDto): Promise<any> => {
  return await getClient().pricingControllerInsertProductPrice(storeId, productPrice)
} 

export const getPricing = async (storeId: string, productId: string): Promise<any> => {
  const res = await getClient().pricingControllerSearchProducts(storeId, productId)
  return res.data.data
} 

export const updatePricing = async (storeId: string, productId: string, productPrice: ProductPriceDto): Promise<any> => {
  const res = await getClient().pricingControllerUpdateProductPrice(storeId, productId, productPrice)
  return res.data
} 


// ============= DOOR =============
export const openDoor = async (storeId: string, doorType: 'entrance' | 'exit'): Promise<any> => {
  return await getClient().doorControllerOpenDoor(storeId, doorType)
} 