
import { useCallback, useEffect, useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google';
import {
    FacebookLoginButton,
    GoogleLoginButton,
} from 'react-social-login-buttons'

import './SocialLogin.css'
import useToast from 'src/CustomHooks/useToast';
import { SocialLoginHandler } from 'mimex-libs/api/endpoints';
import { createLogger } from 'mimex-libs/logger';
import { ERROR, SUCCESS } from 'mimex-libs/helpers/constants';

const logger = createLogger({ component: 'SocialLogin' })

// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes
// const REDIRECT_URI = window.location.href;

interface SocialLoginProps {
    onLoginSuccess: (jwt: string) => void
}

export default function SocialLogin(props: SocialLoginProps) {
    const [renderToast] = useToast()

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            // console.log(codeResponse)
            SocialLoginHandler(codeResponse.code, 'google')
                .then((jwt: string) => {
                    logger.info(`Login succeeded`)
                    renderToast(SUCCESS, "Login succeeded")
                    props.onLoginSuccess(jwt)
                })
                .catch((e) => {
                    logger.error(e.error_description || "")
                })
        },
        flow: 'auth-code',
        scope: 'email profile',
        onError: error => {
            logger.error(error.error_description || "")
            renderToast(ERROR, 'Login error, please try later')
        }
    });

    return (<>
        <div className="social-buttons">
            <GoogleLoginButton onClick={login} />
        </div>
    </>
    )
}